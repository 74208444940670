.birthday-container {
    background-image: url("../../assets/birthday/birthday_bg.png");
    padding-top: 5vh;
    padding-bottom: 5vh;
    background-size: contain;
}

.birthday-head {
    width: min(160px,24vw);
    max-width: 30%;
    margin-top: -20px;
    margin-right: 40px;
    cursor: url("../../assets/cursors/cursor_salute.png") 30 30, pointer;
}

.birthday-text {
    width: min(280px,40vw);
    max-width: 50%;
}

