
@font-face {
    font-family: 'averia-bold';
    src: local('averia-bold'), url(../../fonts/AveriaSansLibre-Bold.ttf) format('truetype');
  }

.footer {
    background-image: url("../../assets/footer_bg.png");
    text-align: center;
    padding: 40px;
    padding-top: 80px;
    background-size: 1000px;
}

.footer-title {
    color: #534741;
    font-size: 24px;
    letter-spacing: 0.4px;
    margin: 20px;
}

.footer-notes {
    color: #736357;
    font-family: 'averia-bold', Futura, sans-serif;
    margin-top: 20px;
}

.contacts-container {
    height: 120px;
}

.contacts-container img {
    max-width: 80px;
    /* width: 8vw; */
    margin: 10px;
    transition: .2s;
}

.contacts-container img:hover {
    /* width: 10vw; */
    max-width: 100px;
    transition: .2s;
}

.contacts-container a {
    cursor: url("../../assets/cursors/karl_love.png") 30 30, pointer;
}

.footer-notes a {
    text-decoration: none;
    color: #534741;
    cursor: url("../../assets/cursors/shojo_what.png") 30 30, pointer;
}