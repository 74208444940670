@font-face {
  font-family: "averia-bold";
  src: local("averia-bold"),
    url(../../fonts/AveriaSansLibre-Bold.ttf) format("truetype");
}

.link_group {
  /* text-align: center; */
  background-size: 1000px;
}

.link-container {
  height: 120px;
  display: flex;
  flex-direction: row;
}

.link-container a {
  flex: 1;
  padding: 2vw;
  max-width: 200px;
}

.link-container img {
  width: 100%;
  transition: 0.2s;
  margin: 0 auto;
}

@media (hover: hover) and (pointer: fine) {
  .link-container img:hover {
    transform: scale(1.1);
  }
}

.link-container a {
  cursor: url("../../assets/cursors/karl_love.png") 30 30, pointer;
}
